.benefit-description {
    text-transform: uppercase;
}

.benefit-card-description {
    color: black
}

.benefit-card-image {
    max-width: 100px;
    min-width: 40px;
}