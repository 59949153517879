.container-status-credential-request-table-valid {
  color: #ef6c00;
  background-color: #fff3e0;
  width: 5.313rem;
  border-radius: 5px;
}

.container-status-credential-request-table-deleted {
  color: #424242;
  background-color: #aeaeae;
  width: 5.313rem;
  border-radius: 5px;
}

.container-status-credential-request-table-resolved {
  color: #12990b;
  background-color: #e8f5e9;
  width: 5.313rem;
  border-radius: 5px;
}

.container-status-credential-request-table-rejected {
  color: #d32f2f;
  background-color: #ffebee;
  width: 5.313rem;
  border-radius: 5px;
}

.container-status-admin-credential-request-table-valid {
  color: #ef6c00;
  background-color: #fff3e0;
  width: 5.313rem;
  border-radius: 5px;
}

.container-status-admin-credential-request-table-deleted {
  color: #424242;
  background-color: #aeaeae;
  width: 5.313rem;
  border-radius: 5px;
}

.container-status-admin-credential-request-table-resolved {
  color: #12990b;
  background-color: #e8f5e9;
  width: 5.313rem;
  border-radius: 5px;
}

.container-status-admin-credential-request-table-rejected {
  color: #d32f2f;
  background-color: #ffebee;
  width: 5.313rem;
  border-radius: 5px;
}

.container-status-credential-table-valid {
  color: #12990b;
  background-color: #e8f5e9;
  width: 5.313rem;
  border-radius: 5px;
}

.container-status-credential-table-askissuer {
  color: #ef6c00;
  background-color: #fff3e0;
  width: 5.313rem;
  border-radius: 5px;
}

.container-status-credential-table-expired {
  color: #424242;
  background-color: #aeaeae;
  width: 5.313rem;
  border-radius: 5px;
}

.container-status-credential-table-revoked {
  color: #d32f2f;
  background-color: #ffebee;
  width: 5.313rem;
  border-radius: 5px;
}

.container-status-credential-table-deletedbysubject {
  color: #d32f2f;
  background-color: #ffebee;
  width: 5.313rem;
  border-radius: 5px;
}

.container-status-admin-credential-table-valid {
  color: #12990b;
  background-color: #e8f5e9;
  width: 5.313rem;
  border-radius: 5px;
}

.container-status-admin-credential-table-askissuer {
  color: #ef6c00;
  background-color: #fff3e0;
  width: 5.313rem;
  border-radius: 5px;
}

.container-status-admin-credential-table-expired {
  color: #424242;
  background-color: #aeaeae;
  width: 5.313rem;
  border-radius: 5px;
}

.container-status-admin-credential-table-revoked {
  color: #d32f2f;
  background-color: #ffebee;
  width: 5.313rem;
  border-radius: 5px;
}

.container-status-admin-credential-table-deletedbysubject {
  color: #d32f2f;
  background-color: #ffebee;
  width: 5.313rem;
  border-radius: 5px;
}

.container-status-contacts-table-row-started {
  color: #ef6c00;
  background-color: #fff3e0;
  width: 5.313rem;
  border-radius: 5px;
}

.container-status-contacts-table-row-completed {
  color: #12990b;
  background-color: #e8f5e9;
  width: 5.313rem;
  border-radius: 5px;
}

.container-status-contacts-table-row-invalid {
  color: #d32f2f;
  background-color: #ffebee;
  width: 5.313rem;
  border-radius: 5px;
}

.container-status-presentation-request-row-valid {
  color: #12990b;
  background-color: #e8f5e9;
  width: 5.313rem;
  border-radius: 5px;
}

.container-status-presentation-request-row-received {
  color: #12990b;
  background-color: #e8f5e9;
  width: 5.313rem;
  border-radius: 5px;
}

.container-status-presentation-request-row-askdeletion {
  color: #d32f2f;
  background-color: #ffebee;
  width: 5.313rem;
  border-radius: 5px;
}

.container-status-presentation-request-row-deletionconfirmation {
  color: #d32f2f;
  background-color: #ffebee;
  width: 5.313rem;
  border-radius: 5px;
}

.container-status-presentation-request-row-created {
  color: #ef6c00;
  background-color: #fff3e0;
  width: 5.313rem;
  border-radius: 5px;
}

.container-status-presentation-request-row-deleted {
  color: #d32f2f;
  background-color: #ffebee;
  width: 5.313rem;
  border-radius: 5px;
}

.container-status-presentation-request-row-pending {
  color: #ef6c00;
  background-color: #fff3e0;
  width: 5.313rem;
  border-radius: 5px;
}

.container-status-presentation-request-row-accepted {
  color: #12990b;
  background-color: #e8f5e9;
  width: 5.313rem;
  border-radius: 5px;
}

.container-status-presentation-request-row-rejected {
  color: #d32f2f;
  background-color: #ffebee;
  width: 5.313rem;
  border-radius: 5px;
}

.holder {
  display: flex;
  justify-content: center;
  align-items: center;
}
