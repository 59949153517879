.date-picker-div {
  position: relative;
  width: 100%;
}

.date-picker-div-small {
  position: relative;
  width: 75%;
}

.date-picker-field {
  position: relative;
  opacity: 0 !important;
  z-index: 2;
}

.date-picker-span {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding-left: 0.5rem;
  z-index: 1;
  display: flex;
  align-items: center;
}

input[type='date']::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
