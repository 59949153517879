.side-panel-navigation-link {
  width: 100%;
  color: #ffffffb2;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
}

.side-panel-navigation-buton:has(> .side-panel-navigation-link.active) {
  background: #FFFFFF1A;
}