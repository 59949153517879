.landing-content {
  top: 25%;
  position: absolute;
  color: #f1f1f1;
  background-color: #00575CD9;
}

.video-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.logo {
  transition: all .25s ease-out;
  flex-direction: column;
  justify-content: center;
  fill: white;
}

.login-button {
  border-color: #07EFEF;
}

.login-button-text {
  text-align: left;
  color: white;
}