.logo {
  width: 100%;
  display: flex;
  transition: all 0.25s ease-out;
  flex-direction: column;
  justify-content: center;
  fill: white;
}
.login-view {
  width: 100vw;
  background-image: url("/public/images/fondo.png");
  color: white;
  background-size: cover;
  flex-grow: 1;
}
.login-video {
  overflow: hidden;
  max-width: 40%;
  max-height: 100vh;
}
.video-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}