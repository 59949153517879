.input-div {
  position: relative;
  width: 100%;
}

.input-field {
  position: relative;
  opacity: 0 !important;
  z-index: 2;
}

.input-span {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
}
