.header-user-logo {
  color: #ffffff;
  max-height: 35px;
  padding: 3 3 3 3;
}

.header-user-logo-background {
  border-radius: 4px;
  display: flex;
  align-items: center;
}
