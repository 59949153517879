.main-landing-container {
    display: block;
}

.main-landing-welcoming-slide {
    height: 100%;
    min-width: 100%;
    position: absolute;
}

.main-landing-rest-slides {
    top: 100%;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
}