.admin-layout {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  min-height: 100%;
  min-width: 100%;
}

.admin-layout-header {
  display: flex;
  min-width: 100%;
  color: white;
  background-color: #012a2d;
}
.admin-layout-side-panel {
  display: flex;
  color: white;
  background-color: #012a2d;
}

.admin-layout-side-panel-stretch {
  width: 100%;
}

.admin-layout-background {
  flex-grow: 1;
  display: flex;
  background-color: #f2e7dc;
}

.admin-layout-background-entity-fake {
  flex-grow: 1;
  display: flex;
  background-color: #a9d9d080;
}